import React, { useEffect, useState } from "react";
import HeaderUser from "./header-user";
import HeaderSearch from "./header-search";
import HeaderOptions from "./header-options";
import LogoBlack from "../../logo-black.png";
import LogoWhite from "../../logo-white.png";
import { useTheme } from "../common/themeContext";

export default function Header() {
  const [logo, setLogo] = useState(LogoBlack);

  const { isDarkMode } = useTheme();

  useEffect(() => {
    const updateLogo = () => {
      setLogo(isDarkMode ? LogoWhite : LogoBlack);
    };

    updateLogo();

    const observer = new MutationObserver(updateLogo);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, [isDarkMode]);

  return (
    <header className="flex justify-between items-center gap-4 p-2 bg-white sticky top-0 z-10 dark:bg-neutral-800">
      <div className="flex gap-1 items-center">
        <img src={logo} width={50} height={50} alt="Logo" />
      </div>
      <HeaderSearch />
      <HeaderOptions />
      <HeaderUser />
    </header>
  );
}
