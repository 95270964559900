import React from "react";
import SettingsMenuOption from "./settings-menu-option";
import { Bell, CreditCard, Eye, Lock, Paintbrush, User } from "lucide-react";

export enum SettingsPanels {
  Profile,
  Visibility,
  Billing,
  Appearance,
  Notifications,
  Privacy,
}

export default function SettingsMenu({
  selectedPanel,
  onMenuOptionClicked,
}: {
  selectedPanel?: SettingsPanels;
  onMenuOptionClicked?: (panel: SettingsPanels) => void;
}) {
  return (
    <div className="h-full">
      <h3 className="text-2xl mb-4">Settings</h3>
      <div className="*:mb-2">
        <SettingsMenuOption
          icon={<User />}
          text="Profile"
          selected={selectedPanel === SettingsPanels.Profile}
          onClick={() => onMenuOptionClicked?.(SettingsPanels.Profile)}
        />
        <SettingsMenuOption
          icon={<Eye />}
          text="Public Visibility"
          selected={selectedPanel === SettingsPanels.Visibility}
          onClick={() => onMenuOptionClicked?.(SettingsPanels.Visibility)}
        />
        <SettingsMenuOption
          icon={<CreditCard />}
          text="Billing"
          selected={selectedPanel === SettingsPanels.Billing}
          onClick={() => onMenuOptionClicked?.(SettingsPanels.Billing)}
        />
        <SettingsMenuOption
          icon={<Paintbrush />}
          text="Appearance"
          selected={selectedPanel === SettingsPanels.Appearance}
          onClick={() => onMenuOptionClicked?.(SettingsPanels.Appearance)}
        />
        <SettingsMenuOption
          icon={<Bell />}
          text="Notifications"
          selected={selectedPanel === SettingsPanels.Notifications}
          onClick={() => onMenuOptionClicked?.(SettingsPanels.Notifications)}
        />
        <SettingsMenuOption
          icon={<Lock />}
          text="Privacy"
          selected={selectedPanel === SettingsPanels.Privacy}
          onClick={() => onMenuOptionClicked?.(SettingsPanels.Privacy)}
        />
      </div>
    </div>
  );
}
