import React from "react";

interface LeaderboardEntryProps {
  name: string;
  score: number;
  date: string;
  avatar: string;
}

export default function LeaderboardEntry({
  name,
  score,
  date,
  avatar,
}: LeaderboardEntryProps) {
  return (
    <div className="flex items-center gap-2">
      <img
        src={avatar}
        alt={name}
        className="rounded-full"
        width={30}
        height={30}
      />
      <div>
        <p className="font-medium text-sm">{name}</p>
        <p className="text-gray-400 text-xs">{date}</p>
      </div>
      <p className="ml-auto font-bold text-sm">{score}</p>
    </div>
  );
}
