import React from "react";
import { Line, LineChart, ResponsiveContainer, Tooltip } from "recharts";
import Card from "src/components/common/card";

export default function Statistics() {
  const data = [
    { name: "Monday", uv: 400 },
    { name: "Tuesday", uv: 56 },
    { name: "Wednesday", uv: 200 },
    { name: "Thursday", uv: 278 },
    { name: "Friday", uv: 0 },
    { name: "Saturday", uv: 239 },
    { name: "Sunday", uv: 349 },
  ];

  return (
    <Card className="min-h-60 flex flex-col flex-grow lg:w-80">
      <h3 className="font-bold text-xl">Statistics</h3>
      <div className="flex gap-4 justify-between mb-3">
        <div className="shadow-xl rounded-2xl p-4 dark:bg-neutral-800">
          <p className="text-xs text-gray-400">Correct Answer %</p>
          <div className="flex items-center gap-2">
            <p className="font-medium text-xl">43.50%</p>
            <div className="px-4 bg-green-200 text-green-500 font-bold rounded-full">
              <p className="text-sm">+2.45%</p>
            </div>
          </div>
        </div>
        <div className="shadow-xl rounded-2xl p-4 dark:bg-neutral-800">
          <p className="text-xs text-gray-400">UCAT Score</p>
          <div className="flex items-center gap-2">
            <p className="font-medium text-xl">720</p>
            <div className="px-4 bg-red-200 text-red-400 font-bold rounded-full">
              <p className="text-sm">-4.75%</p>
            </div>
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height="50%" className={"flex-grow"}>
        <LineChart data={data}>
          <Line type="monotone" dataKey="uv" stroke="#8884d8" />
          <Tooltip
            formatter={(value) => [value, "UV"]}
            labelFormatter={(label) => data[label].name}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
}
