"use client";

import { buttonVariants } from "../components/button";
import { cn } from "../../../lib/utils";
import React from "react";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Logo from "../../../components/common/logo";

export default function Header() {
  const [hamburgerMenuIsOpen, setHamburgerMenuIsOpen] = useState(false);

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) html.classList.toggle("overflow-hidden", hamburgerMenuIsOpen);
  }, [hamburgerMenuIsOpen]);

  useEffect(() => {
    const closeHamburgerNavigation = () => setHamburgerMenuIsOpen(false);
    window.addEventListener("orientationchange", closeHamburgerNavigation);
    window.addEventListener("resize", closeHamburgerNavigation);

    return () => {
      window.removeEventListener("orientationchange", closeHamburgerNavigation);
      window.removeEventListener("resize", closeHamburgerNavigation);
    };
  }, [setHamburgerMenuIsOpen]);

  const { loginWithRedirect } = useAuth0();

  function login() {
    loginWithRedirect({ appState: { returnTo: "/" } });
  }

  return (
    <header className="fixed left-0 top-0 z-50 w-full animate-fade-in border-b backdrop-blur-[12px] [--animation-delay:600ms]">
      <div className="container flex h-[3.5rem] items-center justify-between">
        <Logo className="h-11 w-24" />

        <div className="ml-auto flex h-full items-center">
          <button className="mr-6 text-sm" onClick={login}>
            Log in
          </button>
          <button
            className={cn(
              buttonVariants({ variant: "secondary" }),
              "mr-6 text-sm"
            )}
            onClick={login}
          >
            Sign up
          </button>
        </div>
      </div>
    </header>
  );
}
