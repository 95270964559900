import React from "react";
import Logos from "./sections/logos";
import Problem from "./sections/problem";
import Solution from "./sections/solution";
import HowItWorks from "./sections/how-it-works";
import TestimonialsCarousel from "./sections/testimonials-carousel";
import Features from "./sections/features";
import Testimonials from "./sections/testimonials";
import Pricing from "./sections/pricing";
import FAQ from "./sections/faq";
import Footer from "./sections/footer";
import CTA from "./sections/cta";
import Header from "./sections/header";
import Hero from "./sections/hero";
import { useTheme } from "src/components/common/themeContext";

export default function Landing() {
  const { setTheme } = useTheme();
  setTheme("dark");

  return (
    <main>
      <Header />
      <Hero />
      <Logos />
      <Problem />
      <Solution />
      <HowItWorks />
      <TestimonialsCarousel />
      <Features />
      <Testimonials />
      <Pricing />
      <FAQ />
      <CTA />
      <Footer />
    </main>
  );
}
