import { ChartNoAxesColumn } from "lucide-react";
import React from "react";
import Card from "src/components/common/card";
import MonthProgressBar from "./month-progress-bar";

export default function Progress() {
  return (
    <Card className="flex flex-col">
      <div className="flex justify-between gap-4 items-center mt-3 mb-4">
        <div>
          <p className="text-xs text-gray-400">Total Improvement</p>
          <h3 className="font-bold text-xl">Progress</h3>
        </div>
        <div className="rounded bg-gray-100 p-1">
          <ChartNoAxesColumn
            width={15}
            height={15}
            strokeWidth={2}
            color="black"
          />
        </div>
      </div>
      <div
        className="w-full border-t-2 border-transparent h-1"
        style={{
          borderImage:
            "repeating-linear-gradient(90deg, black 0, black 10px, transparent 10px, transparent 15px) 15",
        }}
      ></div>
      <div className="flex gap-1 flex-grow items-end lg:gap-4">
        <MonthProgressBar month="Jan" progress={30} />
        <MonthProgressBar month="Feb" progress={90} />
        <MonthProgressBar month="Mar" progress={60} />
        <MonthProgressBar month="Apr" progress={70} />
        <MonthProgressBar month="May" progress={60} />
        <MonthProgressBar month="Jun" progress={100} />
        <MonthProgressBar month="Jul" progress={50} />
        <MonthProgressBar month="Aug" progress={80} />
        <MonthProgressBar month="Sep" progress={20} />
        <MonthProgressBar month="Oct" progress={55} />
        <MonthProgressBar month="Nov" progress={35} />
        <MonthProgressBar month="Dec" progress={45} />
      </div>
    </Card>
  );
}
