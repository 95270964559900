import { Ellipsis } from "lucide-react";
import React from "react";
import Card from "src/components/common/card";
import LeaderboardEntry from "./leaderboard-entry";

const LeaderboardEntries = [
  {
    name: "Niket",
    score: 900,
    date: "Today, 16:36",
    avatar: "https://avatar.vercel.sh/james",
  },
  {
    name: "Harsh Ojha",
    score: 626,
    date: "Today, 16:36",
    avatar: "https://avatar.vercel.sh/jack",
  },
  {
    name: "Moe Baksh",
    score: 523,
    date: "Today, 16:36",
    avatar: "https://avatar.vercel.sh/jane",
  },
];

export default function Leaderboard() {
  return (
    <Card className="*:mb-3 flex-grow">
      <div className="bg-gray-gradient rounded-2xl shadow-xl p-4 first:mb-6 dark:bg-neutral-800 dark:bg-none">
        <div className="flex justify-between">
          <p className="text-gray-400 text-xs">Highest UCAT Scores</p>
          <Ellipsis width={15} />
        </div>
        <h3 className="font-bold text-xl">Leaderboard</h3>
      </div>
      {LeaderboardEntries.map((entry) => (
        <LeaderboardEntry {...entry} key={entry.name} />
      ))}
    </Card>
  );
}
