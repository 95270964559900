import React, { useCallback, useEffect, useState } from "react";
import { CalendarEvent } from "../calendar";
import { X } from "lucide-react";

function formatTime(date: Date) {
  return date.toLocaleTimeString(window.navigator.language, {
    hour: "numeric",
    minute: "numeric",
  });
}

export enum BladeState {
  Opening,
  Open,
  Closing,
  Closed,
}

export default function EventBlade({
  event,
  visible = false,
  onClose,
  onStateChange,
}: {
  event?: CalendarEvent;
  visible?: boolean;
  onClose: () => void;
  onStateChange?: (state: BladeState) => void;
}) {
  const [bladeState, setBladeState] = useState<BladeState>(BladeState.Closed);

  const openBladeInternal = useCallback(() => {
    if (bladeState === BladeState.Open) return;
    setBladeState(BladeState.Opening);
    if (onStateChange) onStateChange(BladeState.Opening);
    const timer = setTimeout(() => {
      setBladeState(BladeState.Open);
      if (onStateChange) onStateChange(BladeState.Open);
    }, 700);
    return () => clearTimeout(timer);
  }, [bladeState, onStateChange]);

  const closeBladeInternal = useCallback(() => {
    if (bladeState === BladeState.Closed) return;
    setBladeState(BladeState.Closing);
    if (onStateChange) onStateChange(BladeState.Closing);
    const timer = setTimeout(() => {
      if (onStateChange) onStateChange(BladeState.Closed);
      setBladeState(BladeState.Closed);
    }, 700);
    return () => clearTimeout(timer);
  }, [bladeState, onStateChange]);

  useEffect(() => {
    if (visible) {
      openBladeInternal();
    } else {
      closeBladeInternal();
    }
  }, [openBladeInternal, closeBladeInternal, visible]);

  return (
    <div
      className={`bg-white rounded-l-md transition-[max-width,padding] duration-700 ease-in-out dark:bg-neutral-800 ${
        bladeState === BladeState.Opening || bladeState === BladeState.Open
          ? "max-w-[30rem] p-4"
          : "max-w-0 p-0 overflow-hidden"
      }`}
    >
      {event !== undefined && (
        <>
          <div className="grid grid-cols-[1fr_auto] gap-4 whitespace-nowrap items-center">
            <h3 className="text-3xl">{event.title}</h3>
            <X className="ml-auto cursor-pointer" onClick={onClose}></X>
            <strong>Start:</strong>
            <p>{formatTime(event.start)}</p>
            <strong>End:</strong>
            <p>{formatTime(event.end)}</p>
            <button className="px-4 py-2 rounded-md shadow-xl border col-span-2 dark:shadow-neutral-900 mb-4 dark:border-none">
              Start Now 🚀
            </button>
            <button className="px-4 py-2 rounded-md shadow-xl border col-span-2 dark:shadow-neutral-900 dark:border-none">
              Mark as complete ✅
            </button>
          </div>
        </>
      )}
    </div>
  );
}
