import React, { useState } from "react";
import Layout from "src/components/common/layout";
import ProfilePanel from "./components/profile-panel";
import SettingsMenu, { SettingsPanels } from "./components/settings-menu";
import VisibilityPanel from "./components/visibility-panel";
import AppearancePanel from "./components/appearance-panel";

export default function Settings() {
  const [selectedPanel, setSelectedPanel] = useState(SettingsPanels.Profile);

  function handleMenuOptionClicked(panel: SettingsPanels) {
    setSelectedPanel(panel);
  }

  return (
    <Layout>
      <div className="h-full flex gap-6">
        <SettingsMenu
          selectedPanel={selectedPanel}
          onMenuOptionClicked={handleMenuOptionClicked}
        />
        <hr className="h-full w-[1px] bg-neutral-400" />
        <section className="h-full flex-grow flex flex-col gap-4">
          {selectedPanel === SettingsPanels.Profile && <ProfilePanel />}
          {selectedPanel === SettingsPanels.Visibility && <VisibilityPanel />}
          {selectedPanel === SettingsPanels.Appearance && <AppearancePanel />}
          <div className="mt-auto flex justify-end">
            <button className="ml-auto">Save</button>
          </div>
        </section>
      </div>
    </Layout>
  );
}
