import React from "react";
import Features from "../components/features-vertical";
import Section from "../components/section";
import { Sparkles, Upload, Zap } from "lucide-react";

const data = [
  {
    id: 1,
    title: "1. Sign Up Instantly",
    content:
      "Create your account in seconds and gain immediate access to Doctri AI's powerful UCAT tools.",
    image: "/dashboard.png",
    icon: <Upload className="w-6 h-6 text-primary" />,
  },
  {
    id: 2,
    title: "2. Customize Your Practice",
    content:
      "Choose your focus areas, set your goals, and let the AI-driven platform, featuring the advanced Skills Trainer, tailor your preparation journey.",
    image: "/dashboard.png",
    icon: <Zap className="w-6 h-6 text-primary" />,
  },
  {
    id: 3,
    title: "3. Track and Improve",
    content:
      "Use the interactive dashboard, AI Skills Surge, and expert insights to monitor progress and master every section.",
    image: "/dashboard.png",
    icon: <Sparkles className="w-6 h-6 text-primary" />,
  },
];

export default function Component() {
  return (
    <Section title="How it works" subtitle="Get Started in 3 Easy Steps">
      <Features data={data} />
    </Section>
  );
}
