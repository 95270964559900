import { ChartNoAxesColumn } from "lucide-react";
import React from "react";
import Card from "src/components/common/card";
import { useTheme } from "src/components/common/themeContext";

export default function AiSkillSurge() {
  const { theme } = useTheme();

  return (
    <Card className="p-0">
      <div className="mb-8 p-4 flex flex-col items-center">
        <div className="rounded bg-gray-100 p-2 self-end">
          <ChartNoAxesColumn
            width={15}
            height={15}
            strokeWidth={2}
            color="black"
          />
        </div>
        <p className="mb-2 text-xs font-medium">Precision Practice Questions</p>
        <h3 className="mb-4 text-2xl font-bold">AI Skill Surge</h3>
        <p className="bg-green-100 text-green-500 rounded-full px-2 py-1 text-xs font-bold w-fit">
          + 2.45%
        </p>
      </div>
      <svg
        width="100%"
        height="100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.2711 59.0777C20.2944 67.4777 8.32554 60.2444 3.46318 55.5777L-25.7109 190.078L358.602 205.078C361.033 143.911 362.754 19.2777 350.186 10.0777C334.477 -1.4223 326.623 20.0777 323.818 26.5777C321.012 33.0777 311.475 38.0777 303.62 36.0777C295.765 34.0777 278.934 59.0777 267.152 65.0777C255.371 71.0777 239.1 38.0777 231.807 32.5777C224.513 27.0778 218.342 39.0777 215.537 38.0777C212.731 37.0777 200.95 5.5777 192.534 6.0777C184.118 6.5777 176.825 36.0777 170.653 32.5777C164.482 29.0778 157.749 55.5777 136.991 73.0777C116.232 90.5777 107.817 6.57772 96.596 2.57772C85.3751 -1.42228 82.0089 16.0777 70.7881 32.5777C59.5673 49.0777 54.5179 36.5777 51.1517 32.5777C47.7854 28.5777 40.4919 48.5777 29.2711 59.0777Z"
          fill="url(#paint0_linear_424_321)"
          stroke="#4318FF"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2711 59.0777C20.2944 67.4777 8.32554 60.2444 3.46318 55.5777L-25.7109 190.078L358.602 205.078C361.033 143.911 362.754 19.2777 350.186 10.0777C334.477 -1.4223 326.623 20.0777 323.818 26.5777C321.012 33.0777 311.475 38.0777 303.62 36.0777C295.765 34.0777 278.934 59.0777 267.152 65.0777C255.371 71.0777 239.1 38.0777 231.807 32.5777C224.513 27.0778 218.342 39.0777 215.537 38.0777C212.731 37.0777 200.95 5.5777 192.534 6.0777C184.118 6.5777 176.825 36.0777 170.653 32.5777C164.482 29.0778 157.749 55.5777 136.991 73.0777C116.232 90.5777 107.817 6.57772 96.596 2.57772C85.3751 -1.42228 82.0089 16.0777 70.7881 32.5777C59.5673 49.0777 54.5179 36.5777 51.1517 32.5777C47.7854 28.5777 40.4919 48.5777 29.2711 59.0777Z"
          stroke="black"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_424_321"
            x1="167.848"
            y1="-17.5"
            x2="167.848"
            y2="164.578"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9E3FF" />
            <stop
              offset="0.72165"
              stopColor={theme === "light" ? "#E9E3FF" : "#374151"}
              stopOpacity="0"
            />
          </linearGradient>
        </defs>
      </svg>
    </Card>
  );
}
