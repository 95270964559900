import { useAuth0 } from "@auth0/auth0-react";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import React from "react";

export default function ProfilePanel() {
  const { user, isLoading } = useAuth0();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const isGoogleUser = user?.sub?.startsWith("google-oauth2");

  const input = (value?: string, type: string = "text") => (
    <input
      value={value}
      className="px-2 rounded bg-neutral-300 dark:bg-neutral-700 w-full mb-1 xl:mb-0"
      disabled={isGoogleUser}
      title={isGoogleUser ? "Can not edit" : ""}
      type={type}
    />
  );

  return (
    <div>
      <div className="flex gap-4 items-center mb-4 xl:mb-8">
        <div className="gap-4 grid-cols-1 flex-grow xl:grid xl:grid-cols-[auto_1fr]">
          <h3 className="text-2xl mb-4 xl:col-span-2 xl:mb-0">
            Profile - General
          </h3>
          {isGoogleUser && (
            <div className="flex items-center gap-2 mb-4 xl:mb-0 xl:col-span-2">
              <ExclamationTriangleIcon
                color="orange"
                className="w-10 h-10 xl:w-6 xl:h-6"
              />
              <i className="text-sm">
                The information displayed here is from Google and can only be
                changed by updating your{" "}
                <a
                  href="https://myaccount.google.com"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  Google Profile
                </a>
                .
              </i>
            </div>
          )}
          <p className="text-lg">Name</p>
          {input(user?.name)}
          <p className="text-lg">Email</p>
          {input(user?.email, "email")}
          <p className="text-lg">Location</p>
          {input(user?.address)}
          <p className="text-lg">Birthday</p>
          {input(user?.birthdate, "date")}
          <p className="text-lg">Skool Profile</p>
          {input(user?.birthdate)}
        </div>
        <div className="mx-0 xl:mx-8">
          <img
            src={user?.picture}
            alt="Profile"
            className="rounded-full justify-self-center hidden xl:block"
          />
        </div>
      </div>
      <h3 className="mb-4 text-2xl">Profile - Misc</h3>
      <div className="flex gap-4 items-center border border-red-500 p-4 rounded">
        <i>
          Deleting your account will result in a permanent loss of all your
          information and data stored within Doctri.
        </i>
        <button className="bg-red-500 px-4 py-2 rounded text-white font-bold ml-auto">
          Delete Account
        </button>
      </div>
    </div>
  );
}
