import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventBlade, { BladeState } from "./components/event-blade"; // Import EventBlade component
import Layout from "src/components/common/layout";

export interface CalendarEvent {
  title: string;
  start: Date;
  end: Date;
}

const localizer = momentLocalizer(moment);
const now = new Date();
const events = [
  {
    title: "100 Questions of AR",
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 14),
  },
  {
    title: "30 Minutes of SJT Questions",
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 14),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15),
  },
  {
    title: "Mini Mock Practice",
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 16),
  },
];

export default function DoctriCalendar() {
  const [bladeVisible, setBladeVisibility] = useState(false);
  const [bladeState, setBladeState] = useState<BladeState>(BladeState.Closed);
  const [currentEvent, setCurrentEvent] = useState<CalendarEvent | undefined>(
    undefined
  );

  const eventStyleGetter = () => {
    let style = {
      backgroundColor: "lightblue",
      borderRadius: "5px",
      opacity: 0.8,
      color: "black",
      border: "0px",
      display: "block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    };
    return {
      style: style,
    };
  };

  const handleEventClick = (event: CalendarEvent) => {
    setCurrentEvent(event);
    setBladeVisibility(true);
  };

  const handleBladeClose = () => {
    setBladeVisibility(false);
  };

  const handleBladeStateChange = (state: BladeState) => {
    setBladeState(state);
  };

  return (
    <Layout
      articleClassName={`${bladeState === BladeState.Closed ? "" : "pr-0"}`}
    >
      <div
        className={`flex h-full ${
          bladeState === BladeState.Closed ? "" : "gap-4"
        }`}
      >
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          className="bg-white p-4 h-full rounded-xl flex-grow dark:bg-neutral-800"
          onSelectSlot={(slotInfo) => console.log(slotInfo)}
          onSelectEvent={handleEventClick}
          onDoubleClickEvent={(event) => console.log(event)}
          eventPropGetter={eventStyleGetter}
          dayPropGetter={(date, resourceId) => {
            return { className: "dark:bg-neutral-800" };
          }}
        />
        <EventBlade
          event={currentEvent}
          visible={bladeVisible}
          onClose={handleBladeClose}
          onStateChange={handleBladeStateChange}
        />
      </div>
    </Layout>
  );
}
