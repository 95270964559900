import Layout from "../../components/common/layout";
import React from "react";
import QuestionCategoryStatistics from "./components/question-category-statistics";
import Schedule from "./components/schedule";
import DailyProgress from "./components/daily-progress";
import Leaderboard from "./components/leaderboard";
import TimeSpent from "./components/time-spent";
import Progress from "./components/progress";
import AiSkillSurge from "./components/ai-skill-surge";
import SkillsTrainer from "./components/skills-trainer";
import Statistics from "./components/statistics";
import Profile from "./components/profile";
import { useAuth0 } from "@auth0/auth0-react";
import WelcomeBanner from "./components/welcome-banner";
import QuoteOfTheDay from "./components/quote-of-the-day";

export default function Dashboard() {
  const { isAuthenticated } = useAuth0();

  return (
    <Layout>
      <WelcomeBanner />
      <div className="flex gap-3 mb-3 flex-wrap">
        <div className="flex-grow">
          <div className="mb-3 flex gap-3 justify-between flex-wrap">
            <QuoteOfTheDay />
            <Statistics />
          </div>
          <div className="grid gap-3 grid-cols-4">
            <QuestionCategoryStatistics category="Quantitative" score={436} />
            <QuestionCategoryStatistics category="Decision" score={750} />
            <QuestionCategoryStatistics category="Situational" score={452} />
            <QuestionCategoryStatistics category="Verbal" score={645} />
          </div>
        </div>
        <div className="flex gap-3 flex-wrap flex-grow">
          <Schedule />
          {isAuthenticated && <Profile />}
        </div>
      </div>
      <div className="flex gap-3 mb-3 flex-wrap lg:flex-nowrap">
        <DailyProgress />
        <Leaderboard />
        <Progress />
        <TimeSpent />
        <AiSkillSurge />
      </div>
      <SkillsTrainer />
    </Layout>
  );
}
