import React from "react";
import Card from "../../../components/common/card";
import { ArrowRightIcon } from "lucide-react";
import ScheduleEntry from "./schedule-entry";
import { useNavigate } from "react-router-dom";

export default function Schedule() {
  const currentDate = new Date();
  const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "long" };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);

  const navigate = useNavigate();

  return (
    <Card className="flex flex-col flex-grow">
      <h3 className="text-xl mb-4">Schedule {formattedDate}</h3>
      <div className="*:mb-4">
        <ScheduleEntry title="100 Questions of AR" time="01:00 PM - 02:00 PM" />
        <ScheduleEntry
          title="30 Minutes of SJT Questions"
          time="02:00 PM - 03:00 PM"
        />
        <ScheduleEntry title="Mini Mock Practice" time="03:00 PM - 04:00 PM" />
      </div>
      <div
        className="mt-auto flex items-center gap-2 font-bold text-sm last:mb-0 justify-end cursor-pointer"
        onClick={() => navigate("/calendar")}
      >
        <span className="select-none">View all Tasks</span>
        <ArrowRightIcon width={20} />
      </div>
    </Card>
  );
}
