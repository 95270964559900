import React, { useEffect } from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Dashboard from "./pages/dashboard/dashboard";
import Landing from "./pages/landing/landing";
import NotFound from "./pages/notfound/NotFound";
import { useAuth0 } from "@auth0/auth0-react";
import DoctriCalendar from "./pages/calendar/calendar";
import Settings from "./pages/settings/settings";
import VerbalReasoning from "./pages/questions/verbal-reasoning";

function AuthCallbackHandler() {
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && window.location.pathname === "/") {
      const appState = user!.appState || { returnTo: "/dashboard" };
      window.location.pathname = appState.returnTo;
    }
  }, [isAuthenticated, user]);

  return null;
}

const router = createBrowserRouter([
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/calendar",
    element: <DoctriCalendar />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/",
    element: <Landing />,
    errorElement: <NotFound />,
  },
  {
    path: "/verbal-reasoning",
    element: <VerbalReasoning />,
  },
]);

function App() {
  return (
    <>
      <AuthCallbackHandler />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
