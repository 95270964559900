import React from "react";
import Header from "../header/header";
import Navbar from "../nav/navbar";
import { cn } from "src/lib/utils";

interface LayoutProps {
  articleClassName?: string;
  children: React.ReactNode;
}

export default function Layout({ articleClassName, children }: LayoutProps) {
  return (
    <>
      <div className="bg-gray-200 min-h-dvh min-w-fit dark:bg-neutral-900 flex flex-col">
        <Header />
        <div className="flex flex-grow">
          <Navbar />
          <main className="flex flex-grow overflow-hidden">
            <article
              className={cn(
                "px-8 py-4 dark:text-white w-full sm:mb-12",
                articleClassName
              )}
            >
              {children}
            </article>
          </main>
        </div>
      </div>
    </>
  );
}
