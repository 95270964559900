"use client";

import Marquee from "../../../components/ui/marquee";
import Section from "../components/section";
import { cn } from "../../../lib/utils";
import { motion } from "framer-motion";
import { Star } from "lucide-react";
import React from "react";

export const Highlight = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <span
      className={cn(
        "bg-primary/20 p-1 py-0.5 font-bold text-primary dark:bg-primary/20 dark:text-primary",
        className
      )}
    >
      {children}
    </span>
  );
};

export interface TestimonialCardProps {
  name: string;
  role: string;
  img?: string;
  description: React.ReactNode;
  className?: string;
  [key: string]: any;
}

export const TestimonialCard = ({
  description,
  name,
  img,
  role,
  className,
  ...props // Capture the rest of the props
}: TestimonialCardProps) => (
  <div
    className={cn(
      "mb-4 flex w-full cursor-pointer break-inside-avoid flex-col items-center justify-between gap-6 rounded-xl p-4",
      // light styles
      " border border-neutral-200 bg-white",
      // dark styles
      "dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]",
      className
    )}
    {...props} // Spread the rest of the props here
  >
    <div className="select-none text-sm font-normal text-neutral-700 dark:text-neutral-400">
      {description}
      <div className="flex flex-row py-1">
        <Star className="size-4 text-yellow-500 fill-yellow-500" />
        <Star className="size-4 text-yellow-500 fill-yellow-500" />
        <Star className="size-4 text-yellow-500 fill-yellow-500" />
        <Star className="size-4 text-yellow-500 fill-yellow-500" />
        <Star className="size-4 text-yellow-500 fill-yellow-500" />
      </div>
    </div>

    <div className="flex w-full select-none items-center justify-start gap-5">
      <img
        width={40}
        height={40}
        src={img || ""}
        alt={name}
        className="h-10 w-10 rounded-full ring-1 ring-border ring-offset-4"
      />

      <div>
        <p className="font-medium text-neutral-500">{name}</p>
        <p className="text-xs font-normal text-neutral-400">{role}</p>
      </div>
    </div>
  </div>
);

const testimonials = [
  {
    name: "Alex Rivera",
    role: "Medical Student",
    img: "https://randomuser.me/api/portraits/men/91.jpg",
    description: (
      <p>
        I had struggled with UCAT prep before, but with Doctri AI's personalized
        practice and AI-driven insights, I was able to focus on what I really
        needed to improve.
      </p>
    ),
  },
  {
    name: "Samantha Lee",
    role: "Medical Student",
    img: "https://randomuser.me/api/portraits/women/12.jpg",
    description: (
      <p>
        It's so easy to identify my weaknesses, and the AI-powered suggestions
        really helped me improve my weakest sections.
      </p>
    ),
  },
  {
    name: "Raj Patel",
    role: "Medical Student",
    img: "https://randomuser.me/api/portraits/men/45.jpg",
    description: (
      <p>
        Whenever I had questions, the Medic Support team was there to help me
        with clear and thoughtful advice—such a valuable resource!
      </p>
    ),
  },
  {
    name: "Emily Chen",
    role: "Medical Student",
    img: "https://randomuser.me/api/portraits/women/83.jpg",
    description: (
      <p>
        I can see exactly where I'm improving and which sections need more work.
        It's been motivating and kept me on track.
      </p>
    ),
  },
  {
    name: "Michael Brown",
    role: "Medical Student",
    img: "https://randomuser.me/api/portraits/men/1.jpg",
    description: (
      <p>
        Doctri AI made studying so much more efficient, and I saw a significant
        improvement in my scores in just a few weeks.
      </p>
    ),
  },
  {
    name: "Linda Wu",
    role: "Medical Student",
    img: "https://randomuser.me/api/portraits/women/5.jpg",
    description: (
      <p>
        I was overwhelmed by all the material out there, but Doctri AI gave me a
        clear, structured path to follow.
      </p>
    ),
  },
  {
    name: "Carlos Gomez",
    role: "Medical Student",
    img: "https://randomuser.me/api/portraits/men/14.jpg",
    description: (
      <p>
        The personalized feedback and practice questions helped me feel fully
        prepared for each section of the UCAT.
      </p>
    ),
  },
  {
    name: "Aisha Khan",
    role: "Medical Student",
    img: "https://randomuser.me/api/portraits/women/56.jpg",
    description: (
      <p>
        I had no idea where I was going wrong until I used Doctri AI. Now, I can
        pinpoint my weaknesses and address them head-on.
      </p>
    ),
  },
  {
    name: "Tom Chen",
    role: "Medical Student",
    img: "https://randomuser.me/api/portraits/men/18.jpg",
    description: (
      <p>
        Seeing my progress visually kept me motivated. It was rewarding to see
        how much I was improving with each session.
      </p>
    ),
  },
  {
    name: "Sofia Patel",
    role: "Medical Student",
    img: "https://randomuser.me/api/portraits/women/73.jpg",
    description: (
      <p>
        I used to waste time trying to figure out what to focus on. Doctri AI
        did that for me, making my prep so much more efficient.
      </p>
    ),
  },
  {
    name: "Jake Morrison",
    role: "Medical Student",
    img: "https://randomuser.me/api/portraits/men/25.jpg",
    description: (
      <p>
        I love how the platform adjusts to my strengths and weaknesses. I can
        focus on exactly what I need to improve.
      </p>
    ),
  },
  {
    name: "Nadia Ali",
    role: "Medical Student",
    img: "https://randomuser.me/api/portraits/women/78.jpg",
    description: (
      <p>
        After trying a few other prep resources, Doctri AI has been by far the
        most effective in helping me improve my score.
      </p>
    ),
  },
  {
    name: "Omar Farooq",
    role: "Medical Student",
    img: "https://randomuser.me/api/portraits/men/54.jpg",
    description: (
      <p>
        Doctri AI’s questions felt just like the real exam, which made me feel
        confident going into the test.
      </p>
    ),
  },
];

export default function Testimonials() {
  return (
    <Section
      title="Testimonials"
      subtitle="What our customers are saying"
      className="max-w-8xl"
    >
      <div className="relative mt-6 max-h-screen overflow-hidden">
        <div className="gap-4 md:columns-2 xl:columns-3 2xl:columns-4">
          {Array(Math.ceil(testimonials.length / 3))
            .fill(0)
            .map((_, i) => (
              <Marquee
                vertical
                key={i}
                className={cn({
                  "[--duration:60s]": i === 1,
                  "[--duration:30s]": i === 2,
                  "[--duration:70s]": i === 3,
                })}
              >
                {testimonials.slice(i * 3, (i + 1) * 3).map((card, idx) => (
                  <motion.div
                    key={idx}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{
                      delay: Math.random() * 0.8,
                      duration: 1.2,
                    }}
                  >
                    <TestimonialCard {...card} />
                  </motion.div>
                ))}
              </Marquee>
            ))}
        </div>
        <div className="pointer-events-none absolute inset-x-0 bottom-0 h-1/4 w-full bg-gradient-to-t from-background from-20%"></div>
        <div className="pointer-events-none absolute inset-x-0 top-0 h-1/4 w-full bg-gradient-to-b from-background from-20%"></div>
      </div>
    </Section>
  );
}
