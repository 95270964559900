import { useAuth0 } from "@auth0/auth0-react";
import { ChevronDownCircle, LogOut, Settings } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function HeaderUser() {
  const currentDate = new Date();
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);
  const [optionsOpen, setOptionsOpen] = React.useState(false);
  const navigate = useNavigate();

  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  function openSettings() {
    navigate("/settings");
  }

  return (
    isAuthenticated && (
      <div className="flex gap-2 items-center relative">
        <img
          src={user!.picture}
          alt={user!.given_name}
          width={50}
          height={50}
          className="rounded-full"
          draggable={false}
        />
        <div
          className="cursor-pointer select-none"
          onClick={() => setOptionsOpen(!optionsOpen)}
        >
          <strong>Hey, {user!.given_name}</strong>
          <div className="flex gap-1 items-center text-gray-400">
            <p className="text-xs whitespace-nowrap">{formattedDate}</p>
            <ChevronDownCircle size={14} />
          </div>
        </div>
        <div
          className={`absolute top-full mt-2 w-full bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-700 ease-in-out *:mb-2 dark:bg-neutral-800 ${
            optionsOpen ? "max-h-60 p-2" : "max-h-0 p-0 m-0"
          }`}
        >
          <div
            className="flex items-center gap-2 hover:bg-neutral-200 px-2 py-1 rounded-md dark:hover:bg-neutral-700"
            onClick={openSettings}
          >
            <Settings size={20} />
            <button>Settings</button>
          </div>
          <div
            className="flex items-center gap-2 hover:bg-neutral-200 px-2 py-1 rounded-md dark:hover:bg-neutral-700 text-red-500"
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            <LogOut />
            <button>Log Out</button>
          </div>
        </div>
      </div>
    )
  );
}
