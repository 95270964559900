import { cn } from "../../lib/utils";
import React from "react";

interface NavButtonProps {
  title: React.ReactNode;
  icon?: React.ReactNode;
  active?: boolean;
  className?: string;
  isMinimized?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function NavButton({
  title,
  icon,
  active,
  className,
  isMinimized,
  onClick,
}: NavButtonProps) {
  if (isMinimized) {
    return (
      <button
        onClick={onClick}
        className={cn(
          `rounded p-2 ${
            active
              ? "shadow-gray-400 shadow-md bg-gray-50 dark:bg-neutral-950 dark:shadow-gray-950"
              : ""
          }`,
          className
        )}
      >
        {icon}
      </button>
    );
  }

  return (
    <button
      onClick={onClick}
      className={cn(
        `w-full p-2 flex gap-2 font-medium items-center rounded text-sm whitespace-nowrap ${
          active
            ? "shadow-gray-400 shadow-md font-bold bg-gray-50 dark:bg-neutral-950 dark:shadow-gray-950"
            : ""
        }`,
        className
      )}
    >
      {icon}
      {<span>{title}</span>}
    </button>
  );
}
