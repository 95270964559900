import React from "react";
import Features from "../components/features-horizontal";
import Section from "../components/section";
import { BarChart3, Brain, FileText, LineChart } from "lucide-react";

const data = [
  {
    id: 1,
    title: "Decision Making",
    content:
      "Navigate complex decision-making scenarios with precision as Doctri AI provides you with tailored practice and strategies to sharpen your reasoning and enhance your performance.",
    image: "/dashboard.png",
    icon: <BarChart3 className="h-6 w-6 text-primary" />,
  },
  {
    id: 2,
    title: "Verbal Reasoning",
    content:
      "Boost your reading comprehension and analytical skills with practice that focuses on understanding, interpreting, and evaluating complex passages to help you excel in verbal reasoning.",
    image: "/dashboard.png",
    icon: <Brain className="h-6 w-6 text-primary" />,
  },
  {
    id: 3,
    title: "Quantitative Reasoning",
    content:
      "Refine your quantitative skills with focused drills and real-world data interpretation, helping you solve problems faster and more accurately.",
    image: "/dashboard.png",
    icon: <LineChart className="h-6 w-6 text-primary" />,
  },
  {
    id: 4,
    title: "Situational Judgement (SJT)",
    content:
      "Improve your decision-making in real-world scenarios with practice designed to strengthen your ethical reasoning and judgement under pressure.",
    image: "/dashboard.png",
    icon: <FileText className="h-6 w-6 text-primary" />,
  },
];

export default function Component() {
  return (
    <Section
      title="Features"
      subtitle="Real-Exam Experience with an Elegant Interface"
    >
      <Features collapseDelay={5000} linePosition="bottom" data={data} />
    </Section>
  );
}
