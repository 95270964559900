import { cn } from "../../lib/utils";
import React from "react";

interface VerticalProgressBarProps {
  progress: number;
  className?: string;
  color?: string;
  bgColor?: string;
  width?: string;
}

export default function VerticalProgressBar({
  progress,
  className,
  color = "bg-blue-400",
  width = "w-1",
  bgColor = "bg-gray-200 dark:bg-neutral-800",
}: VerticalProgressBarProps) {
  return (
    <div className={cn("h-full relative", className)}>
      <div className={`${width} h-full ${bgColor} rounded-full`}></div>
      <div
        className={`${width} h-[${progress}%] ${color} rounded-full absolute bottom-0`}
      ></div>
    </div>
  );
}
