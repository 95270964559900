import React from "react";
import NavHeading from "./navbar-heading";
import NavButton from "./navbar-button";
import {
  Album,
  BookOpen,
  Brain,
  Calendar,
  ChevronLeftCircle,
  ClipboardCheck,
  ExternalLink,
  Globe,
  LayoutDashboard,
  Shield,
  Timer,
  TrendingUp,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

const friends = [
  {
    name: "Harsh Ojha",
    img: "https://avatar.vercel.sh/jack",
    score: Math.floor(Math.random() * 801) - 100,
    lastSeen: new Date(
      Date.now() - Math.floor(Math.random() * 10000000000)
    ).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),
  },
  {
    name: "Moe Baksh",
    img: "https://avatar.vercel.sh/jane",
    score: Math.floor(Math.random() * 801) - 100,
    lastSeen: new Date(
      Date.now() - Math.floor(Math.random() * 10000000000)
    ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
  },
  {
    name: "Niket Patel",
    img: "https://avatar.vercel.sh/james",
    score: Math.floor(Math.random() * 801) - 100,
    lastSeen: new Date(
      Date.now() - Math.floor(Math.random() * 10000000000)
    ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
  },
];

export default function Navbar() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(true);

  function openDoctriSkool() {
    window.open("https://www.skool.com/doctri-7340", "_blank");
  }

  return (
    <nav
      className={`px-6 flex flex-col h-[calc(100vh-66px)] sticky top-[66px] py-2 bg-white dark:bg-neutral-800 ${
        isOpen ? "w-60 max-w-60" : "max-w-24"
      } transition-all duration-500 ease-in-out`}
    >
      <ChevronLeftCircle
        className={`absolute -right-3 cursor-pointer text-neutral-500 transition-transform duration-500 ${
          isOpen ? "" : "rotate-180"
        }`}
        onClick={() => setIsOpen(!isOpen)}
      />
      <div className="*:mb-2 flex flex-col">
        <NavHeading
          title={isOpen ? "Overview" : ""}
          className={`first:mb-4 h-4 ${isOpen ? "" : "invisible"}`}
        />
        <NavButton
          title="Dashboard"
          icon={<LayoutDashboard height={20} width={20} />}
          onClick={() => navigate("/dashboard")}
          active={window.location.pathname === "/dashboard"}
          isMinimized={!isOpen}
        />
        <NavButton
          title="Quantitative Reasoning"
          icon={<TrendingUp height={20} width={20} />}
          isMinimized={!isOpen}
        />
        <NavButton
          title="Verbal Reasoning"
          icon={<BookOpen height={20} width={20} />}
          onClick={() => navigate("/verbal-reasoning")}
          active={window.location.pathname === "/verbal-reasoning"}
          isMinimized={!isOpen}
        />
        <NavButton
          title="Abstract Reasoning"
          icon={<Shield height={20} width={20} />}
          isMinimized={!isOpen}
        />
        <NavButton
          title="Decision Making"
          icon={<Calendar height={20} width={20} />}
          isMinimized={!isOpen}
        />
        <NavButton
          title="Situational Judgement"
          icon={<Album height={20} width={20} />}
          isMinimized={!isOpen}
        />
        <NavButton
          title="Mini Mocks"
          icon={<Timer height={20} width={20} />}
          isMinimized={!isOpen}
        />
        <NavButton
          title="UCAT Mock Practice"
          icon={<Brain height={20} width={20} />}
          isMinimized={!isOpen}
        />
        <NavButton
          title={
            <p className="flex items-center gap-1">
              Community <ExternalLink height={15} width={15} />
            </p>
          }
          icon={<Globe height={20} width={20} />}
          onClick={openDoctriSkool}
          isMinimized={!isOpen}
        />
        <NavButton
          title="Improvement"
          icon={<ClipboardCheck height={20} width={20} />}
          isMinimized={!isOpen}
        />
      </div>
      <br />
      <div className={`${isOpen ? "" : "hidden"}`}>
        <NavHeading title="Friends" className="mb-4" />
        {friends.map((review, index) => (
          <div key={index} className="flex justify-between items-center *:mb-3">
            <div className="flex flex-row items-center gap-2">
              <img
                className="rounded-full"
                width="32"
                height="32"
                alt=""
                src={review.img}
              />
              <div className="flex flex-col">
                <figcaption className="text-sm font-medium dark:text-white">
                  {review.name}
                </figcaption>
                <p className="text-xs font-medium dark:text-white/40">
                  Today {review.lastSeen}
                </p>
              </div>
            </div>
            <div>
              <p
                className={`${
                  review.score < 0
                    ? "bg-red-200 text-red-500"
                    : "bg-green-200 text-green-500"
                } rounded p-1 text-xs font-bold`}
              >
                {review.score < 0 ? review.score : `+${review.score}`}
              </p>
            </div>
          </div>
        ))}
      </div>
    </nav>
  );
}
