import React from "react";

interface TimeSpentEntryProps {
  category: string;
  timeSpent: string;
  icon: React.ReactNode;
}

export default function TimeSpentEntry({
  category,
  timeSpent,
  icon,
}: TimeSpentEntryProps) {
  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center gap-2 rounded-full bg-gray-200 p-2">
        {icon}
      </div>
      <p className="font-medium text-sm">{category}</p>
      <p className="ml-auto font-bold text-sm">{timeSpent}</p>
    </div>
  );
}
