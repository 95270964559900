import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Card from "src/components/common/card";

export default function WelcomeBanner() {
  const { user } = useAuth0();

  return (
    <Card className="mb-4 bg-gray-gradient hover:scale-100 dark:bg-neutral-800 dark:bg-none">
      <span className="text-xs text-neutral-500">Hey, {user?.name}</span>
      <h2 className="text-2xl font-bold">Welcome to Doctri</h2>
    </Card>
  );
}
