import React from "react";
import Card from "src/components/common/card";

export default function QuoteOfTheDay() {
  return (
    <Card className="flex flex-col justify-center w-72 text-center p-8">
      <h2 className="mb-4 text-2xl font-bold">Quote of the Day</h2>
      <p className="text-sm font-medium">
        "The only way to do great work is to love what you do."
      </p>
      <p className="text-sm">Steve Jobs</p>
    </Card>
  );
}
