import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import React from "react";

export const BLUR_FADE_DELAY = 0.15;

export const siteConfig = {
  name: "Doctri",
  description:
    "Your ultimate companion for a seamless UCAT preparation experience",
  url: process.env.NEXT_PUBLIC_APP_URL || "http://localhost:3000",
  keywords: ["SaaS", "Template", "Next.js", "React", "Tailwind CSS"],
  links: {
    email: "support@doctri.io",
    twitter: "https://twitter.com/magicuidesign",
    discord: "https://discord.gg/87p2vpsat5",
    github: "https://github.com/magicuidesign/magicui",
    instagram: "https://instagram.com/magicuidesign/",
  },
  pricing: [
    {
      name: "Free",
      href: "#",
      price: "£0",
      period: "month",
      yearlyPrice: "£0",
      features: [
        "Access to limited set of practice questions",
        "Basic tracking of your performance",
        "Limited AI skills feedback",
      ],
      description: "Perfect for individuals and small projects",
      buttonText: "Subscribe",
      isPopular: false,
    },
    {
      name: "Weekly",
      href: "#",
      price: "£10",
      period: "week",
      yearlyPrice: "£7.50",
      features: [
        "Full access to all UCAT sections",
        "Personalized question recommendations",
        "Advanced performance tracking + insights",
        "Access to the Skills Trainer",
      ],
      description: "Ideal for growing businesses and teams",
      buttonText: "Subscribe",
      isPopular: true,
    },
    {
      name: "Monthly",
      href: "#",
      price: "£5",
      period: "month",
      yearlyPrice: "£3",
      features: [
        "Full access to all UCAT sections",
        "Personalized question recommendations",
        "In-depth performance analytics",
        "AI skills trainer for targeted improvement",
        "24/7 Medic Support",
      ],
      description: "For large-scale operations and high-volume users",
      buttonText: "Subscribe",
      isPopular: false,
    },
  ],
  faqs: [
    {
      question: "How does Doctri AI personalize my UCAT preparation?",
      answer: (
        <span>
          Doctri AI customizes your study plan by analyzing your strengths and
          weaknesses, then tailors practice questions and resources to help you
          improve where you need it most.
        </span>
      ),
    },
    {
      question: "Can I track my progress over time?",
      answer: (
        <span>
          Yes! Doctri AI provides an interactive dashboard that allows you to
          monitor your performance, track your improvement, and adjust your
          practice based on real-time insights.
        </span>
      ),
    },
    {
      question: "What sections of the UCAT does Doctri AI cover?",
      answer: (
        <span>
          Doctri AI covers all major UCAT sections: Decision Making, Verbal
          Reasoning, Quantitative Reasoning, and Situational Judgement, offering
          tailored practice for each.
        </span>
      ),
    },
    {
      question: "Is the platform easy to use?",
      answer: (
        <span>
          Absolutely! Doctri AI features a sleek, intuitive interface designed
          to make your preparation simple and efficient, so you can focus on
          mastering the UCAT without any technical distractions.
        </span>
      ),
    },
    {
      question: "How can 24/7 Medic Support help me?",
      answer: (
        <span>
          Our 24/7 Medic Support connects you with qualified medical students
          who can answer your questions, provide guidance, and offer insights to
          keep you motivated and on track throughout your preparation.
        </span>
      ),
    },
  ],
  footer: [
    {
      title: "Product",
      links: [
        { href: "#features", text: "Features", icon: null },
        { href: "#pricing", text: "Pricing", icon: null },
      ],
    },
    {
      title: "Company",
      links: [
        { href: "#", text: "About Us", icon: null },
        { href: "#", text: "Partners", icon: null },
      ],
    },
    {
      title: "Resources",
      links: [
        { href: "#", text: "Community", icon: null },
        { href: "#", text: "Contact", icon: null },
        { href: "#", text: "Support", icon: null },
      ],
    },
    {
      title: "Social",
      links: [
        {
          href: "#",
          text: "Twitter",
          icon: <FaTwitter />,
        },
        {
          href: "#",
          text: "Instagram",
          icon: <RiInstagramFill />,
        },
        {
          href: "#",
          text: "Youtube",
          icon: <FaYoutube />,
        },
      ],
    },
  ],
};

export type SiteConfig = typeof siteConfig;
