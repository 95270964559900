import React, { useEffect, useState } from "react";
import LogoBlack from "../../logo-black.png";
import LogoWhite from "../../logo-white.png";
import DoctriBlack from "../../doctri-black.png";
import DoctriWhite from "../../doctri-white.png";
import { cn } from "../../lib/utils";

export default function Logo({ className }: { className?: string }) {
  const [logo, setLogo] = useState(LogoBlack);
  const [doctriLogo, setDoctriLogo] = useState(DoctriBlack);

  useEffect(() => {
    const updateLogo = () => {
      const isDarkMode = document.documentElement.classList.contains("dark");
      setLogo(isDarkMode ? LogoWhite : LogoBlack);
      setDoctriLogo(isDarkMode ? DoctriWhite : DoctriBlack);
    };

    updateLogo();

    const observer = new MutationObserver(updateLogo);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className={cn("flex gap-1 items-center", className)}>
      <img src={logo} alt="Logo" className="h-full" />
      <img src={doctriLogo} alt="Doctri" className="w-full" />
    </div>
  );
}
