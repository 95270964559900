import React from "react";

interface ScheduleEntryProps {
  title: string;
  time: string;
}

export default function ScheduleEntry({ title, time }: ScheduleEntryProps) {
  return (
    <div className="flex gap-2 last:mb-0">
      <div className="bg-black w-1 rounded-xl h-12"></div>
      <div className="pt-1">
        <p className="text-sm font-bold">{title}</p>
        <p className="text-xs text-gray-400">{time}</p>
      </div>
    </div>
  );
}
