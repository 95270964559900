import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "./components/common/themeContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Auth0Provider
    domain="auth.doctri.io"
    clientId="qqilQRjTDViuKZIs4gbw6BlheCt5isQz"
    authorizationParams={{ redirect_uri: window.location.origin }}
  >
    <React.StrictMode>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  </Auth0Provider>
);
