import BlurFade from "../../../components/ui/blur-fade";
import Section from "../components/section";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../components/carousel";
import { MdOutlineFormatQuote } from "react-icons/md";
import React from "react";

const reviews = [
  {
    name: "Sarah T",
    role: "Medical Student",
    title: "A Game-Changer for UCAT Prep!",
    content:
      "I was overwhelmed by the sheer volume of material out there, but Doctri AI made everything so much easier. The personalized feedback and sleek interface kept me motivated, and I saw huge improvements in just weeks!",
  },
  {
    name: "James P",
    role: "Medical Student",
    title: "The AI Skills Surge is Incredible!",
    content:
      "The targeted practice Doctri AI provided helped me turn my weakest sections into my strongest. I especially loved the clear dashboard that showed my progress in real time.",
  },
  {
    name: "Emily R",
    role: "Medical Student",
    title: "Support That Makes All the Difference!",
    content:
      "Knowing I could reach out for 24/7 medic support was a lifesaver during my preparation. The advice I received was spot on, and it gave me the confidence I needed to succeed.",
  },
];

export default function Component() {
  return (
    <Section
      title="Testimonial Highlight"
      subtitle="What our customers are saying"
    >
      <Carousel>
        <div className="max-w-2xl mx-auto relative">
          <CarouselContent>
            {reviews.map((review, index) => (
              <CarouselItem key={index}>
                <div className="p-2 pb-5">
                  <div className="text-center">
                    <MdOutlineFormatQuote className="text-4xl text-themeDarkGray my-4 mx-auto" />
                    <BlurFade delay={0.25} inView>
                      <h3 className="text-2xl font-semibold mb-2">
                        {review.title}
                      </h3>
                      <h4 className="text-1xl font-semibold max-w-lg mx-auto px-10">
                        {review.content}
                      </h4>
                    </BlurFade>
                    <div>
                      <BlurFade delay={0.25 * 3} inView>
                        <h4 className="text-1xl font-semibold my-2">
                          {review.name}
                        </h4>
                      </BlurFade>
                    </div>
                    <BlurFade delay={0.25 * 4} inView>
                      <div className=" mb-3">
                        <span className="text-sm text-themeDarkGray">
                          {review.role}
                        </span>
                      </div>
                    </BlurFade>
                  </div>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="pointer-events-none absolute inset-y-0 left-0 h-full w-2/12 bg-gradient-to-r from-background"></div>
          <div className="pointer-events-none absolute inset-y-0 right-0 h-full  w-2/12 bg-gradient-to-l from-background"></div>
        </div>
        <div className="md:block hidden">
          <CarouselPrevious />
          <CarouselNext />
        </div>
      </Carousel>
    </Section>
  );
}
