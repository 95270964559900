import { cn } from "../../lib/utils";
import React from "react";

interface CardProps {
  className?: string;
  children?: React.ReactNode;
}

export default function Card({ children, className }: CardProps) {
  return (
    <div
      className={cn(
        "bg-white rounded-xl shadow-md p-4 dark:bg-neutral-700 hover:scale-105 transition-transform duration-200",
        className
      )}
    >
      {children}
    </div>
  );
}
