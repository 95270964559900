import React from "react";
import VerticalProgressBar from "src/components/common/vertical-progress-bar";

export default function MonthProgressBar({
  month,
  progress,
}: {
  month: string;
  progress?: number;
}) {
  return (
    <div className="flex flex-col items-center gap-2 h-full">
      <div className="flex items-end flex-grow">
        <VerticalProgressBar
          progress={progress === 100 ? 100 : 0}
          width="w-4"
          className={`h-[${progress}%]`}
          color="bg-black"
        />
      </div>
      <span className="text-xs text-gray-400">{month}</span>
    </div>
  );
}
