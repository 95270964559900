import React from "react";

function statisticsOption(name: string, description: string) {
  return (
    <div className="flex gap-4 p-4 rounded shadow-lg bg-white dark:bg-neutral-800">
      <div>
        <h4 className="text-lg">{name}</h4>
        <p className="text-sm">{description}</p>
      </div>
      <select
        className="ml-auto rounded-xl px-2 outline-none w-auto dark:bg-neutral-800"
        defaultValue={"private"}
      >
        <option value="everyone">Everyone</option>
        <option value="friends">Friends Only</option>
        <option value="private">Private</option>
      </select>
    </div>
  );
}

export default function VisibilityPanel() {
  return (
    <div>
      <div className="mb-8 flex-grow *:mb-8">
        <section className="*:mb-4">
          <h3 className="text-2xl col-span-2">General</h3>
          {statisticsOption(
            "Last Seen",
            "Share the last time your accessed Doctri with the people you choose."
          )}
          {statisticsOption(
            "Skool Profile",
            "Share your Skool profile with the people you choose."
          )}
        </section>
        <section className="*:mb-4">
          <h3 className="text-2xl col-span-2">Statistics</h3>
          {statisticsOption(
            "Streak",
            "Share your study streak with the people you choose."
          )}
          {statisticsOption(
            "UCAT Score",
            "Share your UCAT score with the people you choose."
          )}
          {statisticsOption(
            "UCAT Score Breakdown",
            "Share your individual UCAT scores with the people you choose."
          )}
          {statisticsOption(
            "Time Spent",
            "Share your time spent on Doctri with the people you choose."
          )}
          {statisticsOption(
            "Time Spent Breakdown",
            "Share the time spent on each section of the UCAT with the people you choose."
          )}
        </section>
      </div>
    </div>
  );
}
