import Card from "../../../components/common/card";
import VerticalProgressBar from "../../../components/common/vertical-progress-bar";
import { CheckCircle2Icon, ChevronUp } from "lucide-react";
import React from "react";

export default function DailyProgress() {
  return (
    <Card className="flex flex-col flex-grow">
      <div className="mb-10 flex gap-8 justify-between">
        <div>
          <p className="text-xs text-gray-400">Incorrect</p>
          <p className="text-xl">258</p>
          <div className="flex gap-1 items-center text-green-500 font-bold">
            <CheckCircle2Icon width={14} />
            <p className="text-sm whitespace-nowrap">On Track</p>
          </div>
        </div>
        <div>
          <div className="mb-1 flex text-green-500 font-bold justify-end items-center">
            <ChevronUp width={20} />
            <p className="text-sm">+2.45%</p>
          </div>
          <button className="bg-gray-gradient rounded-full py-2 px-6 shadow-xl dark:bg-neutral-800 dark:shadow-gray-800 dark:bg-none whitespace-nowrap">
            Try Again
          </button>
        </div>
      </div>
      <div className="h-full min-h-35 flex gap-4 justify-between px-4">
        <VerticalProgressBar progress={50} color="bg-red-500" width="w-3" />
        <VerticalProgressBar progress={30} color="bg-yellow-400" width="w-3" />
        <VerticalProgressBar progress={85} color="bg-red-500" width="w-3" />
        <VerticalProgressBar progress={45} color="bg-green-500" width="w-3" />
        <VerticalProgressBar progress={60} color="bg-yellow-400" width="w-3" />
        <VerticalProgressBar progress={95} color="bg-green-500" width="w-3" />
        <VerticalProgressBar progress={15} color="bg-red-500" width="w-3" />
      </div>
    </Card>
  );
}
