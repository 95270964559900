import React, { createContext, useContext, useState, ReactNode } from "react";

type Theme = "light" | "dark";

interface ThemeContextType {
  theme: Theme;
  isDarkMode: boolean;
  setTheme: (theme: Theme) => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

function getInitialTheme(): Theme {
  if (window.location.pathname === "/") {
    return "dark";
  }

  return "light";
}

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setThemeInternal] = useState<Theme>(getInitialTheme());

  function setTheme(theme: Theme) {
    setThemeInternal((prev) => {
      document.documentElement.classList.remove(prev);
      document.documentElement.classList.add(theme);
      return theme;
    });
  }

  return (
    <ThemeContext.Provider
      value={{ theme, setTheme, isDarkMode: theme === "dark" }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
