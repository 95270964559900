import React from "react";

interface SkillProps {
  img: string;
  category: string;
  title: string;
}

function getCategoryColor(category: string) {
  if (category === "Situational Judgement") {
    return "bg-yellow-300";
  } else if (category === "Quantitative Reasoning") {
    return "bg-green-500";
  } else if (category === "Decision Making") {
    return "bg-red-500";
  } else if (category === "Verbal Reasoning") {
    return "bg-sky-400";
  } else if (category === "Speed Typing") {
    return "bg-orange-400";
  }
}

export default function Skill({ img, category, title }: SkillProps) {
  return (
    <div className="flex flex-col gap-3">
      <img
        src={img}
        className="rounded-2xl shadow-xl object-contain w-[40rem] h-40"
        alt="Sample"
      />
      <p
        className={`px-3 py-0.5 w-fit rounded-sm shadow-xl text-white font-bold text-sm ${getCategoryColor(
          category
        )}`}
      >
        {category}
      </p>
      <h3 className="font-bold text-sm">{title}</h3>
      <div className="flex-grow"></div>
      <div className="h-1 bg-gray-200 rounded shadow-gray-400 shadow-sm dark:bg-neutral-800 dark:shadow-neutral-800"></div>
      <button className="mx-auto h-10 dark:text-white bg-gray-gradient w-full font-medium rounded-xl shadow-xl dark:bg-neutral-800 dark:bg-none">
        Start
      </button>
    </div>
  );
}
