import React from "react";
import Card from "../../../components/common/card";

interface QuestionCategoryStatisticsProps {
  category: string;
  score: number;
}

export default function QuestionCategoryStatistics({
  category,
  score,
}: QuestionCategoryStatisticsProps) {
  return (
    <Card className="flex gap-2 flex-grow justify-between">
      <div>
        <p className="text-sm">{category}</p>
        <p className="text-xl font-bold text-gray-700 dark:text-white">
          {score}
        </p>
      </div>
    </Card>
  );
}
