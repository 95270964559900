import React from "react";
import Card from "src/components/common/card";
import Skill from "./skill";

const skills = [
  {
    img: "/images/dashboard/situational-judgement.jpg",
    category: "Situational Judgement",
    title: "Student's Guide to Mastering Situational Judgement",
  },
  {
    img: "/images/dashboard/quantitative-reasoning.jpg",
    category: "Quantitative Reasoning",
    title: "Mastering Maths in Quantitative Reasoning",
  },
  {
    img: "/images/dashboard/decision-making.jpg",
    category: "Decision Making",
    title: "The Art of Quick Thinking in Decision Making",
  },
  {
    img: "/images/dashboard/verbal-reasoning.jpg",
    category: "Verbal Reasoning",
    title: "Tackling Verbal Reasoning like a Real Doctor",
  },
  {
    img: "/images/dashboard/speed-typing.jpg",
    category: "Speed Typing",
    title: "Time Management and Speed Typing for the UCAT",
  },
];

export default function SkillsTrainer() {
  return (
    <Card>
      <div className="flex gap-4 items-center mb-4">
        <div className="p-4 rounded-full bg-gray-gradient flex items-center justify-center shadow-xl">
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 6C4.79086 6 3 7.79086 3 10C3 11.8638 4.27477 13.4299 6 13.874C6 15.3469 7.19401 16.6669 8.6669 16.6669C9.89773 16.6669 12.4423 16.5726 13.5513 17.5L15.9476 15.1038C16.439 15.4626 17.0778 15.7077 17.8669 15.7077C19.3398 15.7077 20.5338 14.5137 20.5338 13.0408C20.5338 11.8513 19.7344 10.8437 18.659 10.5C19.4673 9.96254 20.0001 9.04349 20.0001 8C20.0001 6.34315 18.6569 5 17.0001 5C16.4743 5 15.98 5.13528 15.5503 5.37295C14.7898 3.96516 13.2124 3 11.5 3C9.54072 3 8.11775 4.28316 7.5 6.03095C7.3362 6.01052 7.16933 6 7 6Z"
              fill="black"
              fillOpacity="0.15"
            />
            <path
              d="M6 13.874C4.27477 13.4299 3 11.8638 3 10C3 7.79086 4.79086 6 7 6C7.16933 6 7.3362 6.01052 7.5 6.03095C8.11775 4.28316 9.54072 3 11.5 3C13.2124 3 14.7898 3.96516 15.5503 5.37295C15.98 5.13528 16.4743 5 17.0001 5C18.6569 5 20.0001 6.34315 20.0001 8C20.0001 9.04349 19.4673 9.96254 18.659 10.5C19.7344 10.8437 20.5338 11.8513 20.5338 13.0408C20.5338 14.5137 19.3398 15.7077 17.8669 15.7077C16.394 15.7077 15.4446 14.8538 15 14C14.0155 14.5095 12.8043 14.1244 12.2948 13.1399M6 13.874C6 15.3469 7.19401 16.6669 8.6669 16.6669C10.267 16.6669 14.0876 16.5075 14.0876 18.641V20.7745M6 13.874C6 12.4011 7.19401 11.3331 8.6669 11.3331M9.86981 8.00393C10.4241 7.04391 11.6517 6.71498 12.6117 7.26925C13.5717 7.82352 13.9006 9.0511 13.3464 10.0111"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <p className="whitespace-nowrap text-xl font-bold">Skills Trainer</p>
        <div className="h-1 bg-gray-200 flex-grow rounded shadow-gray-400 shadow-sm dark:bg-neutral-800 dark:shadow-neutral-800"></div>
      </div>
      <div className="flex gap-4 justify-around px-8 flex-wrap xl:flex-nowrap">
        {skills.map((skill, index) => (
          <Skill key={index} {...skill} />
        ))}
      </div>
    </Card>
  );
}
