import React from "react";
import Layout from "src/components/common/layout";

export default function VerbalReasoning() {
  return (
    <Layout articleClassName="flex flex-col gap-4 pb-6">
      <div className="flex flex-col flex-grow">
        <div>
          <div className="flex justify-between bg-neutral-700 text-white px-2">
            <h3>Verbal Reasoning</h3>
            <span>1 of 44</span>
          </div>
          <div className="flex justify-between bg-neutral-600 text-white px-2">
            <div className="flex gap-8">
              <button>Explain Answer AI</button>
              <button>Calculator</button>
            </div>
            <button>Flag for Review</button>
          </div>
        </div>
        <div className="h-full grid grid-cols-[1.5fr_auto_1fr] gap-4">
          <div className="p-4">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam
              quaerat, fugit nihil cumque dolor ipsam excepturi. Ducimus
              deserunt hic beatae culpa velit vero doloribus maxime asperiores
              dicta! Non, recusandae quam!
            </p>
            <p>
              Source: adapted from{" "}
              <a href="http://comunities.gov.uk">http://comunities.gov.uk</a>
            </p>
          </div>
          <hr className="w-1 h-auto bg-neutral-700" />
          <div className="p-4">
            <p className="mb-4">
              The key difference between living in areas such as the Eden Valley
              and living in a town or City is:
            </p>
            <div className="flex flex-col gap-4">
              <div className="flex gap-4">
                <input
                  type="radio"
                  id="option1"
                  name="option"
                  value="option1"
                />
                <label htmlFor="option1">
                  A. the low incomes of local residents in the countryside
                </label>
              </div>

              <div className="flex gap-4">
                <input
                  type="radio"
                  id="option2"
                  name="option"
                  value="option1"
                />
                <label htmlFor="option2">
                  B. local residents' willingness to organise and do something
                  for each other.
                </label>
              </div>

              <div className="flex gap-4">
                <input
                  type="radio"
                  id="option3"
                  name="option"
                  value="option1"
                />
                <label htmlFor="option3">
                  C. the absence of housing to rent or buy in urban areas.
                </label>
              </div>

              <div className="flex gap-4">
                <input
                  type="radio"
                  id="option4"
                  name="option"
                  value="option1"
                />
                <label htmlFor="option4">
                  D. that civil servants do more for urban dwellers than rural
                  dwellers.
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex bg-neutral-700 text-white justify-between px-2">
          <button>End Exam</button>
          <div className="*:px-4 *:border-x *:border-black">
            <button>Back</button>
            <button>Navigator</button>
            <button>Next</button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="bg-white"></div>
        <div className="bg-white rounded-lg shadow-lg px-4 py-2">
          <h4 className="font-bold text-lg">Answered Questions</h4>
          <div className="text-neutral-400 font-medium">
            <select className="outline-none">
              <option>Mark</option>
            </select>
            <select className="outline-none">
              <option>Grouping</option>
            </select>
            <select className="outline-none">
              <option>Accessibility</option>
            </select>
          </div>
          <h4 className="mb-2 font-bold text-lg">Verbal Reasoning</h4>
          <div className="grid grid-cols-4 gap-2">
            <div className="text-center">
              <div className="flex gap-1 justify-center">
                <p className="bg-green-300 h-6 w-6 rounded">1</p>
                <p className="bg-green-300 h-6 w-6 rounded">2</p>
                <p className="bg-green-300 h-6 w-6 rounded">3</p>
                <p className="bg-green-300 h-6 w-6 rounded">4</p>
              </div>
              <p className="text-sm">00:05/q</p>
            </div>
            <div className="text-center">
              <div className="flex gap-1 justify-center">
                <p className="bg-red-300 h-6 w-6 rounded">5</p>
                <p className="bg-red-300 h-6 w-6 rounded">6</p>
                <p className="bg-green-300 h-6 w-6 rounded">7</p>
                <p className="bg-green-300 h-6 w-6 rounded">8</p>
              </div>
              <p className="text-sm">00:40/q</p>
            </div>
            <div className="text-center">
              <div className="flex gap-1 justify-center">
                <p className="bg-green-300 h-6 w-6 rounded">9</p>
                <p className="bg-green-300 h-6 w-6 rounded">10</p>
                <p className="bg-green-300 h-6 w-6 rounded">11</p>
                <p className="bg-red-300 h-6 w-6 rounded">12</p>
              </div>
              <p className="text-sm">01:05/q</p>
            </div>
            <div className="text-center">
              <div className="flex gap-1 justify-center">
                <p className="bg-green-300 h-6 w-6 rounded">13</p>
                <p className="bg-green-300 h-6 w-6 rounded">14</p>
                <p className="bg-green-300 h-6 w-6 rounded">15</p>
                <p className="bg-green-300 h-6 w-6 rounded">16</p>
              </div>
              <p className="text-sm">00:48/q</p>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </Layout>
  );
}
