import { useAuth0 } from "@auth0/auth0-react";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import React from "react";
import Card from "src/components/common/card";

export default function Profile() {
  const { user } = useAuth0();

  if (!user) {
    return (
      <Card className="flex flex-col items-center justify-center gap-2">
        <ExclamationTriangleIcon color="orange" width={30} height={30} />
        <p className="text-center">
          You have to be logged in to
          <br />
          see view the contents of this card.
        </p>
      </Card>
    );
  }

  return (
    <Card className="flex flex-col items-center justify-center flex-grow">
      <img
        src={user?.picture}
        alt={user?.name}
        width={50}
        height={50}
        className="rounded-full mb-4"
      />
      <strong>{user?.name}</strong>
      <span className="text-gray-300 text-xs whitespace-nowrap">
        {user?.address}
      </span>
    </Card>
  );
}
