import BlurFade from "../../../components/ui/blur-fade";
import Section from "../components/section";
import { Card, CardContent } from "../components/card";
import { Brain, Shield, Zap } from "lucide-react";
import React from "react";

const problems = [
  {
    title: "Information Overload",
    description:
      "Aspiring medical students struggle to navigate endless resources and question banks, often missing the focused practice needed for success.",
    icon: Brain,
  },
  {
    title: "Inefficient Learning",
    description:
      "Traditional preparation methods are slow and overwhelming, leaving students underprepared for the fast-paced demands of the UCAT.",
    icon: Zap,
  },
  {
    title: "Confidence Gaps",
    description:
      "Without personalized insights, students face uncertainty about their performance, hindering their ability to improve effectively.",
    icon: Shield,
  },
];

export default function Component() {
  return (
    <Section
      title="Problem"
      subtitle="Outdated Prep Methods Are Holding You Back"
    >
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
        {problems.map((problem, index) => (
          <BlurFade key={index} delay={0.2 + index * 0.2} inView>
            <Card className="bg-background border-none shadow-none">
              <CardContent className="p-6 space-y-4">
                <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center">
                  <problem.icon className="w-6 h-6 text-primary" />
                </div>
                <h3 className="text-xl font-semibold">{problem.title}</h3>
                <p className="text-muted-foreground">{problem.description}</p>
              </CardContent>
            </Card>
          </BlurFade>
        ))}
      </div>
    </Section>
  );
}
