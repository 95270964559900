import {
  Album,
  ArrowRightIcon,
  BookOpen,
  Ellipsis,
  TrendingUp,
} from "lucide-react";
import React from "react";
import Card from "src/components/common/card";
import TimeSpentEntry from "./time-spent-entry";

const timeSpentEntries = [
  {
    category: "QR",
    timeSpent: "5h",
    icon: <TrendingUp width={20} height={20} color="black" />,
  },
  {
    category: "VR",
    timeSpent: "3h 34min",
    icon: <BookOpen width={20} height={20} color="black" />,
  },
  {
    category: "SJ",
    timeSpent: "1h 28min",
    icon: <Album width={20} height={20} color="black" />,
  },
];

export default function TimeSpent() {
  return (
    <Card className="*:mb-3 flex flex-col flex-grow min-w-52">
      <div className="bg-gray-gradient rounded-2xl shadow-xl p-4 dark:bg-neutral-800 dark:bg-none">
        <div className="flex justify-between">
          <p className="text-gray-400 text-xs">Sessions</p>
          <Ellipsis width={15} />
        </div>
        <h3 className="font-bold text-xl">Time Spent</h3>
      </div>
      <span className="text-xs text-gray-400">Recent</span>
      {timeSpentEntries.map((entry) => (
        <TimeSpentEntry key={entry.category} {...entry} />
      ))}
      <div className="mt-auto flex items-center gap-2 font-bold last:mb-0 justify-end">
        <p className="text-sm">View all</p>
        <ArrowRightIcon width={20} />
      </div>
    </Card>
  );
}
