import React from "react";

export default function SettingsMenuOption({
  icon,
  text,
  selected,
  onClick,
}: {
  icon: React.ReactNode;
  text: string;
  selected?: boolean;
  onClick?: () => void;
}) {
  return (
    <div className="flex gap-1" onClick={onClick}>
      <div className={`w-1 rounded ${selected ? "bg-primary" : ""}`}></div>
      <div
        className={`flex gap-2 items-center flex-grow text-lg px-2 py-1 rounded cursor-pointer select-none whitespace-nowrap ${
          selected ? "bg-neutral-300 dark:bg-neutral-700" : ""
        }`}
      >
        {icon}
        <p className="">{text}</p>
      </div>
    </div>
  );
}
